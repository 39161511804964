import './style.css';
import {eventEmitter, esputnikTracker} from "../../common";

class AuthRegister {

    constructor(data) {
        this._outer = false;
        this._phoneType = 'phoneAuthorization';
        this._emailType = 'emailAuthorization';
        this._emailPattern = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this._fioPattern = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ']+$/;
        this._steps = {
            'auth': ['searchUser', 'authUser', 'authorizedUser'],
            'register': ['fillRegisterData', 'registeredUser'],
            'forgot': ['fillForgotData', 'successPasswordReset']
        };
        this._userInfo = [];
        this._modes = ['auth', 'register', 'forgot'];
        this._user = false;
        this._mode = null;
        this._step = null;
        this._loader = null;
        this._dataLayer = window.dataLayer = window.dataLayer || [];
        this._authType = '';
        this._authParam = false;
        this._authValue = '';
        this._contentType = 'application/json; charset=utf-8';
        this.setLangMessages(data.messages);
        this.url = data.url;
        this.menuPath = data.menuPath;
        this.sessid = data.sessid;
        this.lang = data.lang;
        this.eventEmitter = data.eventEmitter;
        this._hasResendSms = false;
        this._sendingRequest = false;
        this._orderUserIsFound = false;
        this.phoneValidator = null;
        //console.dir(this._messages);
        // this.popupHandler();
        let self = this;

        import(/* webpackChunkName: "phoneValidator" */ '../../lib/phoneValidation/PhoneValidation')
        .then(module => {
            self.phoneValidator = module.default.getInstance();
        });
    }

    get orderUserIsFound() {
        return this._orderUserIsFound;
    }

    set orderUserIsFound(value) {
        this._orderUserIsFound = value;
    }

    initTemplate() {
        this.setOuter(this.getModalTemplate());
        this.setBackDrop(this.getModalBackDrop());
        return this;
    }

    initLoader() {
        let self = this;
        this._loader = document.createElement('div');
        this._loader.className = 'loader-container';
        
        eventEmitter.on('showLoader', () => {
            document.body.appendChild(self._loader.cloneNode(true));
        })
        
        eventEmitter.on('hideLoader', () => {
            let loaderNode = document.querySelector('.loader-container');
            if (loaderNode instanceof HTMLElement)
                loaderNode.parentNode.removeChild(loaderNode);
        })
        
        document.addEventListener('showLoader', e => {
            document.body.appendChild(this._loader.cloneNode(true));
        });

        document.addEventListener('hideLoader', () => {
            let loaderNode = document.querySelector('.loader-container');
            if (loaderNode instanceof HTMLElement)
                loaderNode.parentNode.removeChild(loaderNode);
        });
    }

    getDataLayer() {
        return this._dataLayer;
    }

    switchPasswdFieldType(event) {
        let el = event.target, passwordField, passwordEye;
        let passwdHolder = el.closest('.password_holder');
        if (passwdHolder) {
            passwordField = passwdHolder.querySelector('.password_field');
            passwordEye = passwdHolder.querySelector('.pass_icon');
            (passwordEye.classList.contains('active_eye')) ? passwordEye.classList.remove('active_eye') : passwordEye.classList.add('active_eye');
            passwordField.setAttribute('type', (passwordField.getAttribute('type') === 'password') ? 'text' : 'password');
        }
    }

    addDataLayerEvent() {
        var dataLayer = this.getDataLayer(), event = false, param = false;

        switch (this.getCurrentStep()) {
            case 'searchUser':
                event = 'AuthorizationStep1';
                param = 'Начало авторизации, ввод параметра авторизации.';
                break;
            case 'authUser':
                event = 'AuthorizationStep2';
                if (this.getAuthType() === 'phoneAuthorization') {
                    param = 'Ввод проверочного кода.';
                } else if (this.getAuthType() === 'emailAuthorization') {
                    param = 'Ввод пароля.';
                }
                break;
            case 'authorizedUser':
                event = 'AuthorizationSuccess';
                param = 'Успешная авторизация.';
                break;
            case 'fillRegisterData':
                event = 'Registration';
                param = 'Заполнение данных регистрации.';
                break;
            case 'registeredUser':
                event = 'RegistrationSuccess';
                param = 'Успешная регистрация.';
                break;
        }

        dataLayer.push({
            'event': event,
            'event_param': param
        });
        return this;
    }

    setOuter(el) {
        this._outer = el;
        return this;
    }

    getOuter() {
        return this._outer;
    }

    setBackDrop(el) {
        this._backDrop = el;
        return this;
    }

    getBackDrop() {
        return this._backDrop;
    }

    get url() {
        return this._url;
    }

    set url(url) {
        this._url = url;
    }

    get personalMenuLoaded() {
        return this._personalMenuLoaded;
    }

    set personalMenuLoaded(url) {
        this._personalMenuLoaded = url;
    }

    get menuPath() {
        return this._menuPath;
    }

    set menuPath(url) {
        this._menuPath = url;
    }

    get hasResendSms() {
        return this._hasResendSms;
    }

    set hasResendSms(status) {
        this._hasResendSms = status;
    }

    get sendingRequest() {
        return this._sendingRequest;
    }

    set sendingRequest(status) {
        this._sendingRequest = status;
    }

    addUserInfo(message) {
        this._userInfo.push(message);
        return this;
    }

    getUserInfo() {
        return this._userInfo;
    }

    resetUserInfo() {
        this._userInfo = [];
        return this;
    }

    switchMode(mode) {
        if (mode === 'auth') {
            this.setCurrentMode('auth').setCurrentStep('searchUser').initStep();
        } else if (mode === 'register') {
            this.setCurrentMode('register').setCurrentStep('fillRegisterData').initStep();
        } else if (mode === 'forgot') {
            this.setCurrentMode('forgot').setCurrentStep('fillForgotData').initStep();
        }
    }

    show() {
        const body = document.querySelector('body');
        let callback = document.querySelector('.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible')
        this.clearSpecialMessage();
        body.appendChild(this.getOuter());
        body.appendChild(this.getBackDrop());
        body.classList.add('modal-open');
        body.classList.add('auth-scroll-dis');
        if (callback)
            callback.classList.add('hidden');
        this.getBackDrop().classList.add('in');
        this.getOuter().classList.add('in');

        let input = null;
        if (this.getAuthType() === 'emailAuthorization') {
            input = this.getOuter().querySelector('form [name="user_input_password"]');
        } else if (this.getAuthType() === 'phoneAuthorization') {
            let pinCodeInputList = this.getOuter().querySelectorAll('form [name^="user_input"]');
            if (pinCodeInputList.length > 0)
                input = pinCodeInputList[0];
        }

        if (input instanceof HTMLElement)
            input.focus();

        return this;
    }

    showRegister() {
        const body = document.querySelector('body');
        let callback = document.querySelector('.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible');
        this.clearSpecialMessage();
        body.appendChild(this.getOuter());
        body.appendChild(this.getBackDrop());
        body.classList.add('modal-open');
        body.classList.add('auth-scroll-dis');
        if (callback)
            callback.classList.add('hidden');
        this.getBackDrop().classList.add('in');
        this.getOuter().classList.add('in');

        return this;
    }

    hide() {
        let callback = document.querySelector('.b24-widget-button-wrapper.b24-widget-button-position-bottom-right.b24-widget-button-visible');
        var body = document.querySelector('body');
        body.removeChild(this.getOuter());
        body.removeChild(this.getBackDrop());
        body.classList.remove('modal-open');
        body.classList.remove('auth-scroll-dis');
        if (callback)
            callback.classList.remove('hidden');
        return this;
    }

    goToNextPiece(el) {
        if (el.nextElementSibling instanceof HTMLElement) el.nextElementSibling.focus();
    }

    goToPrevPiece(el) {
        if (el.previousElementSibling instanceof HTMLElement) {
            el.previousElementSibling.value = '';
            el.previousElementSibling.focus();
        }
    }

    checkInput(input) {
        let valid = this.validateInput(input);
        // Check has value
        if (input.value.length > 0) input.classList.add('has-value');
        else input.classList.remove('has-value');
        // Check register step values
        if (valid && this.getCurrentStep() === 'fillRegisterData' &&
            (input.getAttribute('name') === 'user_input_phone' || input.getAttribute('name') === 'user_input_email')) {
            this.searchUserOnRegistration(input.value);
        }
        this.determineValueAvailability(input);
    }

    determineValueAvailability(input) {
        if (input.value.length > 0) input.classList.add('has-value');
        else input.classList.remove('has-value');
        return this;
    }

    validateInput(input) {
        var valid;
        if (input.name !== 'user_input_last_name' && input.name !== 'user_input_name')
            input.value = input.value.trim();

        switch (input.getAttribute('name')) {
            case 'user_input_email':
                valid = this._emailPattern.test(input.value);
                break;
            case 'user_input_name':
                valid = this._fioPattern.test(input.value);
                break;
            case 'user_input_last_name':
                valid = this._fioPattern.test(input.value);
                break;
            case 'user_input_password':
                valid = (input.value.length > 5);
                break;
            case 'user_input_phone':
                valid = this.validatePhone(input.value);
                break;
            case 'user_input_email_phone':
                valid = (this.validatePhone(input.value) || this._emailPattern.test(input.value));
                break;
            case 'user_input_checkword':
                valid = (input.value.length === 8);
                break;
            case 'user_input_policy':
                valid = input.checked;
                break;
            default:
                break;
        }

        if (valid) this.hideError(input);
        /*else this.showInputError(input);*/

        return valid;
    }

    validatePhone(phoneNumber) {
        return this.phoneValidator.validatePhone(phoneNumber);
    }

    checkFullPinCode() {
        let input = this.getOuter().querySelector('[name="user_input_code"]');
        return (input.value.length === 4);
    }

    getForm() {
        return this.getOuter().querySelector('form');
    }

    updateModeBlock() {
        let title = this.getOuter().querySelector('.auth-title'),
            switchLink = this.getOuter().querySelector('.registration-link');

        if (this.getCurrentMode() === 'auth') {
            if (this.getCurrentStep() === 'authorizedUser'){
                switchLink.textContent = '';
            } else {
                title.textContent = this.getMessage('AUTH_LOGIN_BUTTON');
                switchLink.textContent = this.getMessage('AUTH_LOGIN_REGISTER');
                switchLink.dataset['mode'] = 'register';
            }

        } else if (this.getCurrentMode() === 'register') {
            title.textContent = this.getMessage('AUTH_LOGIN_REGISTER');
            switchLink.textContent = this.getMessage('AUTH_LOGIN_BUTTON');
            switchLink.dataset['mode'] = 'auth';
        } else if (this.getCurrentMode() === 'forgot') {
            title.textContent = this.getMessage('AUTH_RESTORE_TITLE');
            switchLink.textContent = this.getMessage('AUTH_LOGIN_BUTTON');
            switchLink.dataset['mode'] = 'auth';
        }
        return this;

    }

    initStep() {
        var self = this;
        this.getOuter().querySelector('form').innerHTML = this.getFormTemplate();
        this.updateModeBlock().clearRequestError();

        // Authorized user 3 sec redirect
        if (this.getCurrentStep() === 'authorizedUser' || this.getCurrentStep() === 'registeredUser') {
            this.addDataLayerEvent();
            setTimeout(function () {
                window.location.reload(true);
            }, 3000);
        } else {
            this.emit('hideLoader');
        }

        if (this.getCurrentStep() === 'successPasswordReset') {
            setTimeout(function () {
                self.setCurrentMode('auth').setCurrentStep('authUser').initStep();
            }, 3000);

            this.addDataLayerEvent();
        }

        return this;
    }

    emit(eventName) {
        let event = new CustomEvent(eventName, {
            detail: {}
        });
        document.dispatchEvent(event);
    }


    collectUserStepInput() {
        var data = false, el = false, valid = true;

        switch (this.getCurrentStep()) {
            case 'searchUser':
                // Find input element

                el = this.getOuter().querySelector('form [name="user_input_email_phone"]');
                // Test value
                if (!this.validatePhone(el.value) && !this._emailPattern.test(el.value)) {
                    valid = false;
                    this.showInputError(el);
                } else data = el.value;

                break;
            case 'authUser':
                if (this.getAuthType() === 'emailAuthorization') {
                    el = this.getOuter().querySelector('form [name="user_input_password"]');
                    if (!el.value.length) {
                        this.showRequestErrors(this.getMessage('AUTH_USER_DATA_ERROR_EMAIL'));
                        valid = false;
                    } else data = el.value;

                } else if (this.getAuthType() === 'phoneAuthorization') {

                    el = this.getOuter().querySelector('.verification-code__input');
                    data = '';

                    if (el.value.length === 4) {
                        data = el.value;
                    } else {
                        this.showInputError(el);
                        valid = false;
                    }
                }

                break;
            case 'fillRegisterData':
                el = this.getOuter().querySelectorAll('[name^="user_input"]');
                data = {};
                var item = false;
                valid = true;

                // Collect all register data
                for (var i = 0; i < el.length; i++) {
                    item = el.item(i);

                    if (item.type === 'checkbox'){
                        data[item.getAttribute('name')] = {
                            'checked': item.checked,
                            'valid': true,
                            'el': item
                        };
                    } else {
                        data[item.getAttribute('name')] = {
                            'value': item.value,
                            'valid': !!(item.value.length),
                            'el': item
                        };
                    }
                }

                if (!data.user_input_policy.checked) {
                    data.user_input_policy.valid = false;
                }

                if (data.user_input_password.value.length < 6) {
                    data.user_input_password.valid = false;
                }

                if (!this.validatePhone(data.user_input_phone.value)) {
                    data.user_input_phone.valid = false;
                }

                if (!this._emailPattern.test(data.user_input_email.value) && data.user_input_email.value.length !== 0) {
                    data.user_input_email.valid = false;
                } else if (data.user_input_email.value === '') {
                    data.user_input_email.valid = true;
                }

                if (!this._fioPattern.test(data.user_input_name.value)) {
                    data.user_input_name.valid = false;
                }

                if (!this._fioPattern.test(data.user_input_last_name.value)) {
                    data.user_input_last_name.valid = false;
                }

                for (var name in data) {
                    if (!data.hasOwnProperty(name)) continue;
                    if (!data[name].valid) {
                        this.showInputError(data[name].el);
                        valid = false;
                    } else {
                        this.hideError(data[name].el);
                    }
                }

                break;
            case 'fillForgotData':
                el = this.getOuter().querySelectorAll('[name^="user_input"]');

                data = {};
                var item = false;

                for (var i = 0; i < el.length; i++) {
                    item = el.item(i);
                    data[item.getAttribute('name')] = {
                        'value': item.value,
                        'valid': !!(item.value.length),
                        'el': item
                    };
                }

                if (!data.user_input_password.value.length) {
                    this.showRequestErrors(this.getMessage('AUTH_USER_DATA_ERROR_EMAIL'));
                    data.user_input_password.valid = false;
                }

                if (data.user_input_password.value.length < 6) {
                    data.user_input_password.valid = false;
                }

                if (!data.user_input_checkword.value.length || data.user_input_checkword.value.length !== 8) {
                    this.showRequestErrors(this.getMessage('AUTH_CHECKWORD_WRONG'));
                    data.user_input_checkword.valid = false;
                }

                for (var name in data) {
                    if (!data.hasOwnProperty(name)) continue;
                    if (!data[name].valid) {
                        this.showInputError(data[name].el);
                        valid = false;
                    } else {
                        this.hideError(data[name].el);
                    }
                }
                break;
            default:
                throw new Error('Unknown current step - ' + this.getCurrentStep() + '.');
                break;
        }

        return {'data': data, 'valid': valid};
    }

    setAuthParam(param) {
        // Prepare phone number
        if (this.getAuthType() === 'phoneAuthorization' && param[0] === '+') {
            param = param.substr(1);
        }

        this._authParam = param;
        return this;
    }

    getAuthParam() {
        return this._authParam;
    }

    setAuthValue(value) {
        this._authValue = value;
        return this;
    }

    getAuthValue() {
        return this._authValue;
    }

    /*getOrderUserIsFound() {
        return this._orderUserIsFound;
    }

    setOrderUserIsFound(value) {
        this._orderUserIsFound = value;
        return this;
    }*/

    searchUserOnRegistration(value) {
        let self = this;
        this.setAuthType(this.resolveAuthType(value)).setAuthParam(value);
        let data = {
            'action': 'searchUser',
            'authParam': this.getAuthParam(),
            'authType': this.getAuthType()
        };
        return this.httpRequest(this.url, data, this._contentType).then((response) => {
            try {
                /*let status = false;*/
                response = JSON.parse(response);
                if (response.hasOwnProperty('found') && response.found === true) {
                    self.orderUserIsFound = true;
                    // Show user message
                    if ('errors' in response && response.errors.length) {
                        for (var i in response.errors) {
                            if (!response.errors.hasOwnProperty(i)) continue;
                            if (response.errors[i].type === 'number_of_requests_exceeded') self.showRequestError(response.errors);
                        }
                    } else {
                        self.setCurrentUser(response.user).setCurrentMode('auth').setCurrentStep('authUser').initStep();
                    }
                }
                else {self.orderUserIsFound = false;}
            } catch (e) {
                console.log(e.message);
            }
        }, self.showRequestError);

    }

    searchUser(value) {
        let self = this;
        this.setAuthType(this.resolveAuthType(value)).setAuthParam(value);
        let data = {
            'action': 'searchUser',
            'authParam': this.getAuthParam(),
            'authType': this.getAuthType()
        };
        this.httpRequest(this.url, data, this._contentType).then((response) => {
            try {
                response = JSON.parse(response);
                /*console.dir(response.code);*/
                if (response.hasOwnProperty('found') && response.found === false) {
                    self.setCurrentMode('register').setCurrentStep('fillRegisterData').initStep();
                } else if (response.hasOwnProperty('found') && response.found === true) {
                    // Show user message
                    if ('errors' in response && response.errors.length) {
                        for (var i in response.errors) {
                            if (!response.errors.hasOwnProperty(i)) continue;
                            if (response.errors[i].type === 'number_of_requests_exceeded') self.showRequestError(response.errors);
                            else self.clearRequestError();
                        }
                    } else {
                        self.setCurrentUser(response.user).setCurrentStep('authUser').initStep();
                    }
                }
                self.sendingRequest = false;

            } catch (e) {
                self.sendingRequest = false;
                console.log(e.message);
            }
        }, self.showRequestError);
    }


    authUser(input) {
        this.emit('showLoader');
        var self = this, data = {
            'action': 'authUser',
            'authParam': this.getAuthParam(),
            'authValue': this.getAuthValue(),
            'authType': this.getAuthType()
        }, request = this.httpRequest(this.url, data, this._contentType), err = false;

        request.then(
            response => {
                try {
                    response = JSON.parse(response);
                    if (response.hasOwnProperty('authorized') && response.authorized === false) {
                        if ('errors' in response) {
                            for (var i in response.errors) {
                                if (response.errors.hasOwnProperty(i)) {
                                    err = response.errors[i];
                                    if (err.type === 'auth_error_code') {
                                        self.showInputError(input);
                                    } else if (err.type === 'auth_error_password') {
                                        input.parentElement.nextElementSibling.textContent = err.message;
                                        self.showInputError(input);
                                    }
                                }
                            }
                        }
                        self.emit('hideLoader');
                    } else if (response.hasOwnProperty('authorized') && response.authorized === true) {
                        if (data.authType == "emailAuthorization"){
                            esputnikTracker.sendCustomerData({'user_email': data.authParam})
                        }
                        else {
                            esputnikTracker.sendCustomerData({'user_phone': data.authParam})
                        }
                        self.setCurrentStep('authorizedUser').initStep();
                    }
                } catch (e) {
                    self.emit('hideLoader');
                    console.log(e.message);
                }
            },
            () => {
                self.emit('hideLoader');
                self.showRequestError();
            }
        );

        return this;
    }

    resendSmsCode() {
        var self = this, data = {
            'action': 'resendSmsCode',
            'authParam': this.getAuthParam(),
            'authType': this.getAuthType()
        };

        return this.httpRequest(this.url, data, this._contentType);
    }

    getCurrentUser() {
        return this._user;
    }

    setCurrentUser(user) {
        this._user = user;
        return this;
    }

    getCurrentUserId() {
        return (this.getCurrentUser().hasOwnProperty('ID')) ? this.getCurrentUser()['ID'] : false;
    }

    /**
     * Send reset password user email
     * @returns {AuthRegister}
     */
    sendResetPasswordData() {
        var self = this, data = {
            'action': 'sendResetPasswordEmail',
            'id': this.getCurrentUserId()
        }, request = this.httpRequest(this.url, data, this._contentType);

        request.then(function (response) {
            try {
                response = JSON.parse(response);
                if (!response.hasOwnProperty('send')) self.showRequestError(self.getMessage('AUTH_SYSTEM_ERROR'));
                else if (response.send === true) {
                    self.setCurrentMode('forgot').setCurrentStep('fillForgotData').initStep();
                } else if (response.hasOwnProperty('errors') && response.errors.length) {
                    self.showRequestError(response.errors);
                }
            } catch (e) {
                self.showRequestError(e.message);
            }
        }, this.showRequestError);

        return this;
    }

    resetPassword(values) {
        var self = this, data = {
            'action': 'resetPassword',
            'password': values.user_input_password.value,
            'checkword': values.user_input_checkword.value,
            'id': this.getCurrentUserId(),
        }, request = this.httpRequest(this.url, data, this._contentType);


        request.then(function (response) {
            try {
                self.sendingRequest = false;
                response = JSON.parse(response);
            } catch (e) {
                self.sendingRequest = false;
                console.log(e.message);
            }

            if (response.hasOwnProperty('reset') && response.reset === false) {
                if ('errors' in response) {
                    /*response.errors.forEach(function (error) {*/
                    self.showRequestErrors(response.errors);
                    /*});*/
                }
            } else if (response.hasOwnProperty('reset') && response.reset === true) {
                self.clearRequestErrors();
                self.setCurrentStep('successPasswordReset').initStep();
            }
        }, self.showRequestError);
    }

    registerUser(values) {
        this.emit('showLoader');
        let phone = '';
        if (values.user_input_phone.value[0] === '+') {
            phone = values.user_input_phone.value.substr(1);
        } else {
            phone = values.user_input_phone.value;
        }
        var self = this, data = {
            'action': 'registerUser',
            'name': values.user_input_name.value,
            'last_name': values.user_input_last_name.value,
            'password': values.user_input_password.value,
            'email': values.user_input_email.value,
            'phone': phone
        }, request = this.httpRequest(this.url, data, this._contentType);

        request.then(response => {
                try {
                    response = JSON.parse(response);
                } catch (e) {
                    self.emit('hideLoader');
                    console.log(e.message);
                }

                if (response.hasOwnProperty('registered') && response.registered === false) {
                    if ('errors' in response) {
                        for (var i in response.errors) {
                            if (response.errors.hasOwnProperty(i) && response.errors[i].type === 'user_exist') {
                                self.showRequestError(response.errors);
                                setTimeout(function () {
                                    self.setCurrentMode('auth').setCurrentStep('searchUser').initStep();
                                }, 3000);
                            }
                        }
                    }
                    self.emit('hideLoader');
                } else if (response.hasOwnProperty('registered') && response.registered === true) {
                    esputnikTracker.sendCustomerData({'user_email': data.email, 'user_name': data.name, 'user_phone': data.phone})
                    self.setCurrentStep('registeredUser').initStep();
                }
                self.sendingRequest = false;
            },
            () => {
                self.emit('hideLoader');
                self.sendingRequest = false;
                self.showRequestError();
            });
    }

    setCurrentStep(step) {
        var steps = this.getModeSteps(this.getCurrentMode());
        if (steps.indexOf(step) === -1) throw new Error('Unknown step - ' + step + '.');
        this._step = step;
        return this;
    }

    getCurrentStep() {
        return this._step;
    }

    setCurrentMode(mode) {
        if (this.getModes().indexOf(mode) === -1) throw new Error('Unknown mode - ' + mode + '.');

        this._mode = mode;
        return this;
    }

    getCurrentMode() {
        return this._mode;
    }

    getSteps() {
        return this._steps;
    }

    getModes() {
        return this._modes;
    }

    getModeSteps(mode) {
        if (this.getModes().indexOf(mode) === -1) throw new Error('Unknown mode - ' + mode + '.');

        return this.getSteps()[mode];
    }

    resolveAuthType(value) {
        if (this.validatePhone(value)) return this._phoneType;
        else if (this._emailPattern.test(value)) return this._emailType;

        return null;
    }

    getAuthType() {
        return this._authType;
    }

    setAuthType(type) {
        this._authType = type;
        return this;
    }

    showInputError(input) {
        input.parentElement.nextElementSibling.style.display = "block";
        return this;
    }

    hideError(input) {
        input.parentElement.nextElementSibling.style.display = "";
        return this;
    }

    httpRequest(url, data, contentType) {
        // Add request sessid, lang
        data.lang = this.lang;
        let sessid = this.sessid;
        data = JSON.stringify(data);

        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-type', contentType);
            xhr.setRequestHeader('X-Bitrix-Csrf-Token', sessid);
            xhr.onload = function () {
                if (this.status === 200) {
                    resolve(this.response);
                } else {
                    var error = new Error(this.statusText);
                    error.code = this.status;
                    reject(error);
                }
            };
            xhr.onerror = function () {
                reject(new Error("Network Error"));
            };
            xhr.send(data);
        });
    }

    setLangMessages(messages) {
        this._messages = messages;
        return this;
    }

    getMessage(name) {
        return (this._messages.hasOwnProperty(name)) ? this._messages[name] : '';
    }

    /**
     * Make auth modal template. {formContent} for replace with form data
     * @returns {string}
     */
    getModalTemplate() {
        // Make root element
        let el = document.createElement('div');
        el.className = 'modal fade';
        el.id = 'modal-main';
        el.setAttribute('role', 'dialog');
        el.tabIndex = '-1';

        el.innerHTML = `<div class="auth-overlay auth-overlay--active">
                            <div class="auth-pop-up">
                            <a href="#" data-dismiss="modal">
                            <img class="close-modal" src="/local/templates/staleks/components/bitrix/system.auth.form/login.popup/images/close-icon.svg" alt="">
                            </a>
                                <div class="auth-wrap">
                                    <div>
										<div class="favorites-login-info" style="display: none;"></div>
											<span class="auth-title">${(this.getCurrentMode() === 'auth') ? this.getMessage('AUTH_AUTHORIZATION') : this.getMessage('AUTH_LOGIN_REGISTER')}</span>
											<form action="" novalidate></form>
											<div class="request_error_block"></div>
									</div>
                                    ${(this.getCurrentStep() !== 'authorizedUser')
            ? `<span> <a href="#" class="registration-link" data-action="switchMode" data-mode="register">${(this.getCurrentMode() === 'auth') ? this.getMessage('AUTH_LOGIN_REGISTER') : this.getMessage('AUTH_AUTHORIZATION')}</a></span>`
            : "" }
                                </div>
                            </div> 
                      </div>`;
        return el;
    }

    searchUserTemplate() {
        let authParam = (this.getAuthParam()) ? this.getAuthParam() : '',
            inputClass = (authParam.length) ? 'has-value' : '';
        return `<div class="authorization">
							<input value='${authParam}' name="user_input_email_phone" id="auth-input" class="authorization__input ${inputClass}" autocomplete="off">
                            <label for="auth-input" class="auth-input__label">${this.getMessage('AUTH_LOGIN_DESCRIPTION')}</label>
						</div>
						<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR_EMAIL_PHONE')}</span>
						<span class="auth-tel-info">${this.getMessage('AUTH_USER_PHONE_DESCRIPTION')}</span>
                        <button class="auth-button">${this.getMessage('AUTH_NEXT_BUTTON')}</button>`
    }

    fillForgotDataTemplate() {
        let hasValue = (this.getAuthParam().length > 0);
        let email = this.getAuthParam();
        return `<div class="authorization checkword">
                        <input name="user_input_checkword" id="auth-word" class="authorization__input">
                        <label for="auth-word" class="authorization-input__label">${this.getMessage('AUTH_RESTORE_CONTROL_CODE')}</label>
                    </div>
                    <p class="error-text checkword">${this.getMessage('AUTH_CHECKWORD_WRONG')}</p>
                    <span class="auth-checkword-info">${this.getMessage('AUTH_USER_KEYWORD_DESCRIPTION') + email}</span>
                    <div class="authorization field password_holder">
                        <input type="password" name="user_input_password" id="auth-password" class="authorization__input password_field">
                        <label for="auth-password" class="auth-input__label">${this.getMessage('AUTH_NEW_PASSWORD')}</label>
                        <button type="button" class="input-with-label__password-img pass_icon"></button>	
                    </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR_EMAIL')}</p>
                    <button class="auth-button">${this.getMessage('AUTH_RESTORE')}</button>
                    <span class="auth-checkword-info spam">${this.getMessage('AUTH_USER_KEYWORD_SPAM_DESCRIPTION')}</span>`;
    }

    fillRegisterDataTemplate() {
        let hasValue = (this.getAuthParam().length > 0);
        return `<div class="authorization">
                        <input name="user_input_name" id="auth-name" type="text" class="authorization__input">
                        <label for="auth-name"  class="auth-input__label">${this.getMessage('AUTH_NAME')}*</label>
                    </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</p>
                    
                    <div class="authorization">
                        <input name="user_input_last_name" id="auth-last-name" type="text" class="authorization__input">
                        <label for="auth-last-name"  class="auth-input__label">${this.getMessage('AUTH_LAST_NAME')}*</label>
                    </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</p>
                    
                    <div class="authorization">
                        <input name="user_input_phone" id="auth-tel" type="tel" class="authorization__input">
                        <label for="auth-tel" class="auth-input__label">${this.getMessage('AUTH_PHONE')}*</label>
                    </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR_PHONE')}</p>
                    
                    <div class="authorization">
                        <input name="user_input_email" id="auth-e-mail" type="email" class="authorization__input" autocomplete="off">
                        <label for="auth-e-mail" class="auth-input__label">${this.getMessage('AUTH_EMAIL')}</label>
                    </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_EMAIL_ERROR')}</p>
                    
                    <div class="authorization field password_holder">
                        <input type="password" name="user_input_password" id="auth-password2" autocomplete="off" class="authorization__input password_field">
                        <label for="auth-password2" class="auth-input__label">${this.getMessage('AUTH_PASSWORD')}*</label>
                        <button type="button" class="input-with-label__password-img pass_icon"></button>
                    </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR_EMAIL')}</p>
                    <div class="checkbox-block">
                                    <input name="user_input_policy" type="checkbox" checked id="order-confirm-policy-auth">
                                    <label for="order-confirm-policy-auth">
                                     <div class="order-rules-checkbox-text">
                                            ${this.getMessage('SOA_ORDER_CONFIRM')}
                                            <a target="_blank" class="popup-policy-label-auth" href="/soglashenie.html">
                                            ${this.getMessage('SOA_ORDER_CONFIRM_TERMS')}</a> и 
                                            <a target="_blank" class="popup-policy-label-auth" href="/politika-konfidencialnosti.html" >${this.getMessage('SOA_ORDER_CONFIRM_POLICY')}</a>
                                      </div>
                                    </label>
                                </div>
                    <p class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR_POLICY')}</p>
                    <button class="auth-button">${this.getMessage('AUTH_REGISTER')}</button>`;

    }

    authUserTemplate() {
        if (this.getAuthType() === 'phoneAuthorization') {
            return this.authUserPhoneTemplate();
        } else if (this.getAuthType() === 'emailAuthorization') {
            return this.authUserEmailTemplate();
        }
    }

    authorizedUserTemplate() {
        return `<div class="row">${this.getMessage('AUTH_SUCCESSFUL')}</div>`;
    }

    successPasswordResetTemplate() {
        return `<div class="row">${this.getMessage('AUTH_RESTORE_SUCCESSFUL')}</div>`;
    }

    authUserEmailTemplate() {
        return `<div class="authorization field password_holder">
                        <input type="password" name="user_input_password" id="auth-password3" class="authorization__input password_field">
                        <label for="auth-password3" class="auth-input__label">${this.getMessage('AUTH_PASSWORD')}</label>
                        <button type="button" class="input-with-label__password-img pass_icon"></button>
                    </div>
                    <span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR_EMAIL')}</span>
                    <a href="#"><span class="auth-link auth-link--password auth_password_forgot" href="" data-action="switchMode" data-mode="forgot">${this.getMessage('AUTH_RESTORE_PASSWORD')}</span></a>
                    <a href="#"><span class="auth-link auth-link--password change_param" href="">${this.getMessage('AUTH_CHANGE_EMAIL')}</span></a>
                    <button class="auth-button">${this.getMessage('AUTH_AUTHORIZATION')}</button>`;
    }

    authUserPhoneTemplate() {
        return `<span class="sms-text">${this.getMessage('AUTH_INSERT_SMS_CODE')}</span>
                        <div class="verification-code field field-sms input-holder">
                            <input maxlength="4" name="user_input_code" class="verification-code__input auth_secret_code input input-num-sms">
                            <div class="verification-code__border"></div>
                        </div>
                        <span class="error-text">${this.getMessage('AUTH_SMS_CODE_WRONG')}</span>
                        <div class="error-solution-wrap">
                            <span class="sms-text sms-text--margin">${this.getMessage('AUTH_SMS_CODE_NOT_DELIVERED')}</span>
                            <a  class="change_param">
                            <a href="#" class="auth-link resend_code" href="">${this.getMessage('AUTH_SMS_CODE_RESEND')}</a>
                        </div>
                        <a href="#" class="auth-link auth-link--margin change_param" href="">${this.getMessage('AUTH_CHANGE_PHONE')}</a>
                        <button class="auth-button">${this.getMessage('AUTH_AUTHORIZATION')}</button>`;
    }

    registeredUserTemplate() {
        return `<div class="row">${this.getMessage('AUTH_SUCCESSFUL_REGISTERED')}</div>`;
    }

    getFormTemplate() {
        return this[this.getCurrentStep() + 'Template']();
    }

    getModalBackDrop() {
        let el = document.createElement('div');
        el.className = 'modal-backdrop fade';
        return el;
    }

    clearRequestError() {
        let errorBlock = this.getOuter().querySelector('.request_error_block');
        if (errorBlock instanceof HTMLElement) {
            errorBlock.innerHTML = '';
        }
    }

    clearRequestErrors() {
        let errorBlock = this.getOuter().querySelector('.error-text');
        if (errorBlock instanceof HTMLElement) {
            errorBlock.innerHTML = '';
        }
    }

    showRequestError(messages) {
        this.sendingRequest = false;
        let errorBlock = this.getOuter().querySelector('.request_error_block');
        if (errorBlock instanceof HTMLElement) {
            if (messages instanceof Array) {
                let html = '';
                messages.forEach((item, index) => {
                    html += `<span class="error-text" style="display: block">${item.message}</span>`;
                });
                errorBlock.innerHTML = html;
            } else {
                errorBlock.innerHTML = `<span class="error-text" style="display: block">${messages}</span>`;
            }
        }
        errorBlock.style.display = 'block';
        return this;
    }

    showRequestErrors(messages) {
        this.sendingRequest = false;
        let errorBlock = this.getOuter().querySelector('.error-text');
        if (errorBlock instanceof HTMLElement) {
            if (messages instanceof Array) {
                let html = '';
                messages.forEach((item, index) => {
                    html += `${item.message}`;
                });
                errorBlock.innerHTML = html;
            } else {
                errorBlock.innerHTML = `${messages}`;
            }

        }
        errorBlock.style.display = 'block';
        return this;
    }


    showSpecialMessage(message) {
        let messageNode = this.getOuter().querySelector('.favorites-login-info');
        if (!(messageNode instanceof HTMLElement)) return this;
        messageNode.innerHTML = '<span>' + message + '</span>';
        messageNode.style.display = 'block';
        return this;
    }


    clearSpecialMessage() {
        let messageNode = this.getOuter().querySelector('.favorites-login-info');
        if (!(messageNode instanceof HTMLElement)) return;
        messageNode.innerHTML = '';
    }

    loadPersonalMenu(url) {
        return this.httpRequest(this.menuPath, {'action': 'personalMenu', 'url': url}, 'text/html');
    }

    popupHandler()
    {
        $('.header-links__item--login svg').on('click', function openCloseLoginPopup() {
            $(this).parent().toggleClass('active');
            $('.login-popup').toggleClass('open');
        });

        $(document).on('click tachstart', function (e) {
            if(e.target.id === 'bx-forgot-password-link') return;
            if (!$(e.target).closest('.header-links__item--login svg, .login-popup, .password-icon').length) {
                $('.header-links__item--login').removeClass('active');
                $('.login-popup').removeClass('open');
            }
            e.stopPropagation();
        });
    }

}

export default AuthRegister