'use strict'

import './CatalogSubSections.css'
import React, { useEffect, useState } from 'react'

function CatalogSubSections (props) {
  // limit items count in one column
  const [columnLimit, setColumnLimit] = useState(null)

  const columnLimitDefault = 13
  const columnsLimit = props.popularBlockUsed ? 4 : 5
  const columnItemHeight = 35 // column item height in px

  const [subSectionsChunks, setSubSectionsChunks] = useState([])

  // Состояние для управления видимостью подразделов
  const [visibleSections, setVisibleSections] = useState({});

  // Функция для переключения видимости подразделов
  const toggleSubSectionVisibility = (sectionId) => {
    setVisibleSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }));
  };

  // calculation of columnLimit items
  useEffect(() => {
    if (props.rootRef.hasOwnProperty('current') || props.rootRef.current instanceof HTMLElement) {
      let wrapper_height = props.rootRef.current.offsetHeight
      let tmp_column_limit = Number(wrapper_height / columnItemHeight)
      if (!isNaN(tmp_column_limit)) setColumnLimit(tmp_column_limit)
      else setColumnLimit(columnLimitDefault)
    } else {
      setColumnLimit(columnLimitDefault)
    }
  }, [props.rootRef])

  // calculation of subsections chunks
  useEffect(() => {
    if (!props.sections.length || !props.allSections.length) setSubSectionsChunks([])
    else {
      let sub_sections_chunks = []
      let sub_sections_chunk = []

      for (let section of props.sections) {
        let sub_sections = getSubSections(section)

        // slice sections for one column
        if (sub_sections.length > columnLimit) {

          // check current chunk
          if (sub_sections_chunk.length) {
            // save current chunk
            sub_sections_chunks.push(sub_sections_chunk)
            // check chunks limit
            if (sub_sections_chunks.length >= columnsLimit) break
            sub_sections_chunk = []
          }

          // main section (depth level 2) с кнопкой "+"
          sub_sections_chunk.push(
              <div className="menu-column-header qqq" key={`header-${section.itemId}`}>
                <a href={section.link} className="menu-column-title">{section.title}</a>
                {section.showVisible === "1" && (
                    <span
                        className="toggle-subsection"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleSubSectionVisibility(section.itemId);
                        }}
                    >
                {visibleSections[section.itemId] ? '−' : '+'}
              </span>
                )}
              </div>
          )

          let counter = 0
          for (let sub_section of sub_sections) {
            let sub_section_class = `side-menu-link ${Number(sub_section.newSection) ? 'new' : ''}`

            // Если showVisible=1 и раздел не отмечен как видимый, то скрываем элементы
            const isHidden = section.showVisible === "1" && !visibleSections[section.itemId];

            if (!isHidden) {
              sub_sections_chunk.push(
                  <a
                      href={sub_section.link}
                      className={sub_section_class}
                      key={sub_section.itemId}
                      data-parent={section.itemId}
                  >
                    {sub_section.title}
                  </a>
              )
              counter++
              if (counter >= columnLimit) break
            }
          }

          // save current chunk
          sub_sections_chunks.push(sub_sections_chunk)
          // check chunks limit
          if (sub_sections_chunks.length >= columnsLimit) break
          sub_sections_chunk = []

        } else {

          if (sub_sections_chunk.length + sub_sections.length > columnLimit) {
            // save current chunk
            sub_sections_chunks.push(sub_sections_chunk)
            // check chunks limit
            if (sub_sections_chunks.length >= columnsLimit) break
            sub_sections_chunk = []
          }

          // main section (depth level 2) с кнопкой "+"
          sub_sections_chunk.push(
              <div className="menu-column-header qqq" key={`header-${section.itemId}`}>
                <a href={section.link} className="menu-column-title">{section.title}</a>
                {section.showVisible === "1" && (
                    <span
                        className="toggle-subsection"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleSubSectionVisibility(section.itemId);
                        }}
                    >
                {visibleSections[section.itemId] ? '−' : '+'}
              </span>
                )}
              </div>
          )

          let counter = 0
          for (let sub_section of sub_sections) {
            let sub_section_class = `side-menu-link ${Number(sub_section.newSection) ? 'new' : ''}`

            // Если showVisible=1 и раздел не отмечен как видимый, то скрываем элементы
            const isHidden = section.showVisible === "1" && !visibleSections[section.itemId];

            if (!isHidden) {
              sub_sections_chunk.push(
                  <a
                      href={sub_section.link}
                      className={sub_section_class}
                      key={sub_section.itemId}
                      data-parent={section.itemId}
                  >
                    {sub_section.title}
                  </a>
              )
              counter++
              if (counter >= columnLimit) break
            }
          }

        }

      }

      // save last chunk
      if (sub_sections_chunk.length && sub_sections_chunks.length < columnsLimit)
        sub_sections_chunks.push(sub_sections_chunk)

      setSubSectionsChunks(sub_sections_chunks)
    }
  }, [columnLimit, visibleSections])

  // find and return subsections for section
  function getSubSections (section) {
    let sub_sections = []

    for (let sub_section of props.allSections) {
      if (!sub_section.hasOwnProperty('parentId') || sub_section.parentId === null ||
        Number(sub_section.parentId) !== Number(section.itemId)) continue

      sub_sections.push(sub_section)
    }

    return sub_sections
  }

  return (
    <>
      {
        subSectionsChunks.map((sub_sections_chunk, index) => {
          return <div className="menu-column" key={`submenu-column-${index}`}>{sub_sections_chunk}</div>
        })
      }
    </>
  )
}

export default CatalogSubSections