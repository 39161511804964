'use strict';

import {application, eventEmitter, lang, axiosConfig} from 'devSrc/common';
import axios from 'axios'

export class BitrixSmallCart {
	constructor(params) {
		if (typeof params !== 'object') return;
		this.cartId = params.cartId;
		this.ajaxPath = params.ajaxPath;
		this.templateName = params.templateName;
		this.arParams = params.arParams;
		this.closeMessage = params.closeMessage;
		this.openMessage = params.openMessage;
		this.activate();
		this.popupHandler();
	}
	
	activate() {
		this._positionScroll = 0;
		this.cartElement = document.getElementById(this.cartId);
		this.processing = false;
		this.basketEvent = false;
		this.productHistory = {};
		this.setCartBodyClosure = this.closure('setCartBody');
		this.snappingQuantityElements();
		
		//BX.addCustomEvent(window, 'OnBasketChange', this.closure('refreshCart', {}));
		eventEmitter.on('OnBasketChange', () => this.refreshCart({}));
		eventEmitter.on('openBasket', () => this.openBasket());
		
		// TODO возможно удалить
		$(document).on('click', function (e) {
			let buy = document.querySelector('.menu-popup__buy');
			if (buy === e.target && !self.popup.classList.contains('open')) {
				e.preventDefault();
				$('.header-links__item--basket').trigger('click');
			}
		});
		
		$('.reviews-block__answer.has-answers').on('click', function openSubAnswers(e) {
			e.preventDefault();
			$(this).parents('.reviews-block__bottom').siblings('.reviews-block__sublist').slideToggle('500');
		});
	}
	
	closure(fname, data) {
		let obj = this;
		return data
			? function () {
				obj[fname](data)
			}
			: function (arg1) {
				obj[fname](arg1)
			};
	}
	
	refreshCart(data) {
		if (this.basketEvent) return;
		
		let self = this;
		let formData = new FormData();
		
		data.sessid = application.getSessionId();
		data.siteId = application.getSiteId();
		data.lang = application.getLanguageId();
		data.templateName = this.templateName;
		data.arParams = JSON.stringify(this.arParams);
		
		//Generate form
		for (let k in data) {
			if (data.hasOwnProperty(k))
				formData.append(k, data[k]);
		}
		
		//Save last position of scroll
		let scrollBlock = document.querySelector('.basket-popup__list');
		if (scrollBlock instanceof HTMLElement) this._positionScroll = scrollBlock.scrollTop;
		
		//Request to receive an updated layout of the cart
		axios.post(self.ajaxPath, formData, axiosConfig)
		.then(response => {
			return response.data;
		})
		.then(result => {
			// Устанавливаем тело корзины
			self.setCartBody(result);
		})
		.catch(error => {
			console.error('Ошибка при выполнении запроса:', error);
		});
		
	}
	
	setCartBody(result) {
		let self = this;
		this.processing = false;
		if (!this.cartElement) return;
		
		this.cartElement.innerHTML = result.replace(/#CURRENT_URL#/g, this.currentUrl);
		
		let itemsCount = this.cartElement.querySelectorAll('.basket-popup__item').length;
		let cartBtn = document.querySelector('.header-links__item--basket');
		let quantityIcon = document.querySelector('.quantity');
		
		quantityIcon.innerHTML = itemsCount;
		
		if (itemsCount > 0 && !cartBtn.classList.contains('has-quantity')) {
			cartBtn.classList.add('has-quantity');
		} else if (itemsCount <= 0) {
			cartBtn.classList.remove('has-quantity');
		}
		
		this.snappingQuantityElements();
		
		if (this.basketEvent) {
			let popupNode = this.cartElement.querySelector('[data-role="basket-item-list"]');
			if (popupNode instanceof HTMLElement)
				popupNode.classList.add('open');
			
			this.basketEvent = false;
		}
		
		this.popupHandler();
		eventEmitter.emit('basketUpdated', {count: itemsCount})
		
		$('.checkout-basket__color-current, .product-colors__item').on('mouseover', function hoverColor() {
			$(this).find('.product-colors__info').addClass('active');
		});
		
		$('.basket-popup__color, .product-colors__item').on('mouseover', function hoverColor() {
			$(this).find('.product-colors__info').addClass('active');
		});
		
		$('.checkout-basket__color-current, .product-colors__item, .product-colors__info').on('mouseleave', function () {
			$('.product-colors__info').removeClass('active');
		});
		
		$('.basket-popup__color, .product-colors__item, .product-colors__info').on('mouseleave', function () {
			$('.product-colors__info').removeClass('active');
		});
		
		//Set scroll position from last position
		let scrollBlock = document.querySelector('.basket-popup__list');
		if (scrollBlock instanceof HTMLElement) scrollBlock.scroll({top: this._positionScroll});
		
	}
	
	removeItemFromCart(id, productId) {
		this.processing = true;
		this.refreshCart({sbblRemoveItemFromCart: id});
		this.basketEvent = true;
		// BX.onCustomEvent('OnRemoveItemFromCart', [productId]);
		eventEmitter.emit('OnRemoveItemFromCart', [productId]);
	}
	
	
	updateItemCartQuantity(id, quantity, productId, currentQuantity = null) {
		this.processing = true;
		this.refreshCart({sbblUpdateItemQuantity: id, sbblQuantity: quantity});
		let direction = !isNaN(currentQuantity) && Number(quantity) > Number(currentQuantity) ? 'up' : 'down'
		eventEmitter.emit('OnUpdateItemQuantity', {productId: Number(productId), direction: direction});
		this.basketEvent = true;
	}
	
	numberPicker(node) {
		let productId = node.querySelector('.product-quantity').getAttribute('data-itemId');
		let recProductId = node.querySelector('.product-quantity').getAttribute('data-productId');
		let quantityUp = node.querySelector('button[data-role="plus"]');
		let quantityDown = node.querySelector('button[data-role="minus"]');
		let productDelete = node.querySelector('[data-role="delete"]');
		
		let quantityInput = node.querySelector("input.product-quantity__input");
		let min = parseInt(quantityInput.getAttribute('min'), 10);
		let max = parseInt(quantityInput.getAttribute('max'), 10);
		if (max === 0 || isNaN(max)) max = Infinity;
		let self = this;
		let currentQuantity = quantityInput.value;
		
		let inputFunc = function inputFunc(onClick) {
			if (self.processing) return;
			let i = parseInt(quantityInput.value, 10);
			if (max === 1) {
				if (onClick || self.productHistory.hasOwnProperty(productId)) {
					quantityUp.disabled = true;
					quantityDown.disabled = true;
					let elm = ((quantityDown.parentNode).parentNode).parentNode;
					let maximum = document.createElement('div');
					maximum.id = "full_elm";
					maximum.className = "maximum";
					maximum.innerHTML = "<p class='error' style='font-size: 70%;'>Количество товара ограничено.</p>";
					elm.after(maximum);
					quantityInput.value = max;
					
					self.productHistory[productId] = true;
				}
				return;
			} else if (i <= min || !i) {
				quantityInput.value = min;
				quantityUp.disabled = false;
				quantityDown.disabled = true;
				quantityUp.style.display = '';
			} else if (i >= max) {
				quantityInput.value = max;
				quantityUp.disabled = true;
				quantityDown.disabled = false;
				quantityUp.style.display = 'none';
				
				let elm = ((quantityDown.parentNode).parentNode).parentNode;
				let maximum = document.createElement('div');
				maximum.id = "full_elm";
				maximum.className = "maximum";
				maximum.innerHTML = "<p class='error' style='font-size: 70%;'>Количество товара ограничено.</p>";
				elm.after(maximum)
			} else {
				quantityUp.disabled = false;
				quantityDown.disabled = false;
				quantityUp.style.display = '';
			}
			
			if (Number(currentQuantity) !== Number(quantityInput.value))
				self.updateItemCartQuantity(productId, quantityInput.value, recProductId, currentQuantity);
			
		};
		
		let changeFunc = function changeFunc(qty) {
			if (self.processing) return;
			let q = parseInt(qty, 10);
			let i = parseInt(quantityInput.value, 10);
			quantityInput.value = i + q;
			inputFunc(true);
		};
		
		quantityDown.addEventListener('click', () => {
			changeFunc(-1);
		})
		quantityUp.addEventListener('click', () => {
			changeFunc(1);
		})
		productDelete.addEventListener('click', () => {
			let id = productDelete.dataset.id;
			let productId = productDelete.dataset.productId;
			this.removeItemFromCart(id, productId);
		})
		quantityInput.addEventListener('change', () => {
			inputFunc();
		})
		inputFunc(false);
	}
	
	
	snappingQuantityElements() {
		let self = this;
		let cartItems = this.cartElement.querySelectorAll('.basket-popup__item');
		if (cartItems.length <= 0) return;
		cartItems.forEach(function (item) {
			self.numberPicker(item);
		});
	}
	
	openBasket() {
		/*console.trace();
		console.info('openBasket')*/
		document.body.style.overflow = 'hidden';
		$('.header-links__item--basket').addClass('active');
		this.popup.classList.add('open');
	}
	
	closeBasket() {
		/*console.trace();
		console.info('closeBasket', this.popup)*/
		document.body.style.overflow = 'visible';
		$('.header-links__item--basket').removeClass('active');
		this.popup.classList.remove('open');
	}
	
	popupHandler() {
		let self = this;
		
		this.popup = document.querySelector('.basket-popup');
		
		document.querySelector('.header-links__item--basket').addEventListener('click', event => {
			console.info(event.target);
			event.stopPropagation();
			self.openBasket();
		});
		
		this.popup.addEventListener('click', event => {
			let target = event.target;
			console.info(target);
			if (target.classList.contains('basket-popup__close') || target.closest('.basket-popup__close')) {
				event.stopPropagation();
				event.preventDefault();
				self.closeBasket();
			}
		});
	}
}
